import { SET_DIRECTORIES, CLEAR_DIRECTORIES } from '../types';
import { apiRoot } from '../../services/helpers';
import axios from 'axios';

export const getDirectories = () => async (dispatch:any) => {
    try {
        const res:any = await axios.get( apiRoot() + '/directory/catlist');
        if (res.data.success) {
            dispatch({type: SET_DIRECTORIES, payload: res.data.data.records});
        }
    } catch(err) {
        console.log('error getting locations');
    }
}

