import { SET_DIRECTORIES, CLEAR_DIRECTORIES } from '../types';

const initialState:any = {
    dirsLoaded: false,
    dirs: [],
}

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case SET_DIRECTORIES:
            return {
                ...state,
                dirsLoaded: true,
                dirs: payload
            }

        case CLEAR_DIRECTORIES:
            return initialState;

        default:
            return state;
    }
}
