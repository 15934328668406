import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { logout } from '../redux/actions/auth';
import { createNewMemberPlan, getMembers } from '../redux/actions/members';
import axios from 'axios';
import { apiRoot } from '../services/helpers';
import MainWrapper from './layout/MainWrapper';

const Users = (props: any) => {
    const { auth, members, logout } = props;

    const initArr: any = [];
    const initUser: any = {
        user_id: '',
        fname: '',
        lname: '',
        email: '',
        password: 'php2024&&!',
        admin: '10',
        active: '1',
        channels: []
    };
    const [formMode, setFormMode] = useState('new');
    const [usersLoaded, setUsersLoaded] = useState(-1);
    const [channelsLoaded, setChannelsLoaded] = useState(-1);
    const [users, setUsers] = useState(initArr);
    const [channels, setChannels] = useState(initArr);
    const [newUser, setNewUser] = useState(initUser);
    const [alert, setAlert] = useState({ display:false, type:'', message:'' } );

    const updateNewUser = (field: string, value: any) => {
        let tmpUser = { ...newUser };
        if (field === 'channel') {
            if (tmpUser.channels.includes(value)) {
                const indx = tmpUser.channels.indexOf(value);
                if (indx > -1) {
                    tmpUser.channels.splice(indx, 1);
                }
            } else {
                tmpUser.channels.push(value);
            }
        } else {
            tmpUser[ field ] = value;
        }
        setNewUser(tmpUser);
    }

    const submitNewUser = async () => {
        const res:any = await axios.post(apiRoot() + '/auth/user', newUser);
        if (res.data.success) {
            await getUsers();
            setNewUser( initUser );
        }
    }

    const submitEditUser = async () => {
        const res:any = await axios.put(apiRoot() + '/auth/user', newUser);
        if (res.data.success) {
            await getUsers();
            setNewUser( initUser );
            setFormMode('new');
        }
    }

    const clearEditForm = async () => {
        setNewUser( initUser );
        setFormMode('new');
    }

    const getUsers = async () => {
        const res: any = await axios.get(apiRoot() + '/auth/users');
        if (res.data.success) {
            setUsersLoaded(1);
            setUsers(res.data.users);
        } else {
            setUsersLoaded(0);
        }
    }

    const editUser = ( u:any ) => {
        setFormMode('edit');
        console.log('u', u);
        setNewUser({
            user_id: u.user_id,
            fname: u.fname,
            lname: u.lname,
            email: u.email,
            password: '',
            admin: u.admin,
            active: u.active,
            channels: u.channels.map( (c:any) => c.id )
        })
    }

    const deleteUser = async ( userId:number ) => {
        const yn = window.confirm('Are you sure you want to delete this user?');
        if (yn) {
            const res = await axios.delete(apiRoot() + '/auth/user/'+userId);
            if (res.data.success) {
                setAlert({ ...alert, display: true, type: 'success', message: 'User deleted successfully.' });
                await getUsers();
            } else {
                setAlert({ ...alert, display: true,
                    type: 'danger', message: res.data.errors[0]
                });
            }
        }
    }

    const hideAlert = () => {
        setAlert( { ...alert, display:false });
    }

    const isAdmin = parseInt(auth.admin) === 100;

    useEffect(() => {
        if (usersLoaded === -1) {
            (async () => {  await getUsers();  })();
        }
    }, [usersLoaded]);

    return (
        <MainWrapper>
        <div className="container">
            <div className="row">
                <div className={"col-12"}>
                    <h3 className={'subtitle'}>Users</h3>
                    { alert.display ?
                        <div className={'alert alert-'+alert.type}>
                            <div style={{float:'right'}} onClick={()=>hideAlert()}><i className={'fas fa-times'}></i> </div>
                            <b>{ alert.message }</b>
                        </div>
                        : <></>
                    }
                    {usersLoaded === 1 ?
                        <table className='table'>
                            <thead>
                            <tr>
                                <th>UserId</th>
                                <th>Last</th>
                                <th>First</th>
                                <th>Email</th>
                                <th>Admin</th>
                                <th>Active</th>
                                <th>Channels</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((u: any) =>
                                <tr>
                                    <td>{u.user_id}</td>
                                    <td>{u.lname}</td>
                                    <td>{u.fname}</td>
                                    <td>{u.email}</td>
                                    <td>{u.admin}</td>
                                    <td>{u.active}</td>
                                    <td>{u.channels.length}</td>
                                    <td><button className={"btn btn-info btn-sm" + (!isAdmin ? ' btn-disabled' : '')} disabled={!isAdmin} onClick={()=>editUser(u)}><i className="fas fa-pencil"></i></button> &nbsp;
                                        <button className={"btn btn-danger btn-sm" + (!isAdmin ? ' btn-disabled' : '')} disabled={!isAdmin} onClick={()=>deleteUser(u.user_id)}><i className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        : <></>
                    }
                </div>
            </div>
            <br/><br/><br/>

            { isAdmin ?
                <div className="form-wrapper">
                    <div className="row">
                        <div className="col-6">
                            <h4>{ formMode === 'new' ? 'Create New User' : 'Edit User'}</h4>
                            <hr/>
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className={"form-control"} value={newUser.fname}
                                       onChange={(e: any) => updateNewUser('fname', e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className={"form-control"} value={newUser.lname}
                                       onChange={(e: any) => updateNewUser('lname', e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className={"form-control"} value={newUser.email}
                                       onChange={(e: any) => updateNewUser('email', e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Is Admin?</label>
                                <select className={"form-control"} value={newUser.admin}
                                        onChange={(e: any) => updateNewUser('admin', e.target.value)}>
                                    <option value={'10'}>No</option>
                                    <option value={'100'}>Yes</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Is Active?</label>
                                <select className={"form-control"} value={newUser.active}
                                        onChange={(e: any) => updateNewUser('active', e.target.value)}>
                                    <option value={'1'}>Yes</option>
                                    <option value={'0'}>No</option>
                                </select>
                            </div>
                            { formMode === 'new' ?
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="text" className={"form-control"} value={newUser.password}
                                           onChange={(e: any) => updateNewUser('password', e.target.value)}/>
                                </div>
                                :
                                <></>
                            }

                            <hr/>
                            {formMode === 'new' ?
                                <button className="btn btn-success" onClick={() => submitNewUser()}>Submit New User</button>
                                :
                                <div>
                                    <button className="btn btn-info" onClick={() => submitEditUser()}>Update User Info</button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-secondary" onClick={() => clearEditForm()}>Cancel</button>
                                </div>
                            }
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Channels:</label>
                            </div>
                            {auth.channels.map((c: any) => c.calendar_only==='0' ?
                                <div className="form-group" style={{marginBottom:'2px'}}>
                                    <label style={{ fontWeight: 400 }}>
                                        <input type="checkbox" style={{ zoom: 1.5, position: 'relative', top: '4px' }}
                                               checked={newUser.channels.includes(c.id)}
                                               value={c.id}
                                               onClick={(e: any) => updateNewUser('channel', e.target.value)}/> &nbsp;{c.name}
                                    </label>
                                </div>
                                :<></>
                            )}
                        </div>
                    </div>
                </div>
                :
                <></>
            }


        </div>
        </MainWrapper>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    members: state.members,
    channels: state.auth.channels
});

export default connect(mapStateToProps, { logout, getMembers, createNewMemberPlan })(Users);

