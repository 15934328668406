import React, { useEffect, useMemo, useState } from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle:any = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#AAA',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#666',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginLeft: '-15px',
    marginRight: '-15px',
    backgroundImage: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
};

const focusedStyle:any = {
    borderColor: '#2196f3'
};

const acceptStyle:any = {
    borderColor: '#00e676'
};

const rejectStyle:any = {
    borderColor: '#ff1744'
};

const thumbsContainer:any = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb:any = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner:any = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img:any = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const StyledDropzone = ( props:any ) => {
    const { setImageFile, imageUrl, showPreview } = props;

    const initArr:any = [];
    const [files, setFiles] = useState(initArr);
    const [errors, setErrors] = useState("");

    const preview = !!showPreview;

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
        accept: {'image/png': ['.png','.jpg','.jpeg']},
        maxSize: 8388608,
        onDrop: (acceptedFiles:any, fileRejections:any) => {
            setFiles(acceptedFiles.map((file:any) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            fileRejections.forEach((file:any) => {
                file.errors.forEach((err:any) => {
                    if (err.code === "file-too-large") {
                        setErrors(`Error: ${err.message}`);
                    }

                    if (err.code === "file-invalid-type") {
                        setErrors(`Error: ${err.message}`);
                    }
                });
            });
        }
    });

    const style:any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(imageUrl.length ? {backgroundImage:"url('"+imageUrl+"')"} : {fontSize:'50px'})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const thumbs = files.map((file:any) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect( () => {
        setImageFile( acceptedFiles[0] );
    }, [acceptedFiles]);

    return (
        <>
        <div className="container">
            { files.length ?
                <div {...getRootProps({style} ) }>
                    <input {...getInputProps()} />
                    {preview ?
                        <img src={files[ 0 ].preview} style={{ maxWidth: "100%" }}/>
                        :
                        <img src={imageUrl} style={{maxWidth:"100%"}} />
                    }
                </div>
              : imageUrl.length ?
                  <div {...getRootProps({style} ) }>
                      <input {...getInputProps()} />
                      <img src={imageUrl} style={{maxWidth:"100%"}} />
                  </div>
                :
                <div {...getRootProps({style} ) }>
                    <input {...getInputProps()} />
                    <p>&nbsp;</p>
                    <p style={{fontSize:'16px'}}>Drag and Drop your image here, or click to select files</p>
                    <p>&nbsp;</p>
                </div>
            }
        </div>
    { errors.length ?
        <div className="alert alert-danger" style={{marginTop:'16px'}}>{errors}
            <div style={{float:'right',cursor:'pointer'}} onClick={()=>setErrors("")}><i className="fas fa-times"></i></div>
        </div>
        :
        <></>
    }
    </>
    );
}

export default StyledDropzone;
