import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import { apiRoot } from '../services/helpers';

const PlaceView = ( props:any ) => {
    const { p, dirlist } = props;

    const placeChecked = useRef( false );
    const [exists, setExists] = useState( false );
    const [hasPlaceData, setHasPlaceData] = useState( false );
    const [dirId, setDirId] = useState( "0" );
    const [catId, setCatId] = useState( "0" );

    const getPlaceData = async ( mode:string ) => {
        try {
                const newData = {
                    directory_id: dirId,
                    directory_category_id: catId,
                    place_id: p.place_id,
                    name: p.name,
                    description: '',
                    phone: '',
                    website: '',
                    lat: '',
                    lng: '',
                    data: p,
                    mode: mode
                };

                // INSERT THE DATA INTO PHPASSPORT
                try {
                    const ins:any = await axios.post( apiRoot()+'/directory/entry', newData );
                    console.log('ins result', ins);
                    placeChecked.current = false;
                    checkPlace();
                }
                catch(err) {
                    console.log('error on insert', err);
                }

            }
        catch(err) {
            console.log('error', err);
        }
    }


    const checkPlace = async () => {
        try {
            const res:any = await axios.get(apiRoot()+'/directory/placeexists/'+p.place_id);
            console.log('place res', res);
            if (res.data.success) {
                setExists( res.data.exists );
                setHasPlaceData( res.data.place_data );
                setDirId( res.data.dir_id );
                setCatId( res.data.cat_id );
            }
            placeChecked.current = true;
        }
        catch( err ) {
            console.log('err', err);
        }
    }

    const onChangePlace = ( newCatId:string ) => {
        let newDirId = "";
        dirlist.map( (dl:any) => {
            if (dl.id === newCatId) {
                newDirId = dl.directory_id;
            }
        });
        setCatId( newCatId );
        setDirId( newDirId );
    }

    useEffect(() => {
        (async () => {
            if (!placeChecked.current) {
                await checkPlace();
            }
        })();
    }, [exists, hasPlaceData, placeChecked.current])

    return (
        <tr key={p.place_id}>
            {/*<td>{p.place_id}</td>*/}
            <td>{p.name}</td>
            <td>{p.vicinity}</td>
            <td>{p.business_status}</td>
            <td>
                <select value={catId} className={'form-control'} onChange={(e:any)=>onChangePlace(e.target.value)}>
                    <option value={"0"}>--- SELECT ---</option>
                    { dirlist.map( (d:any) =>
                        <option value={d.id}>{d.directory_id}: {d.name}</option>
                    )}
                </select>
            </td>
            <td>
                { !exists ?
                    <button className={'btn btn-sm btn-success'} disabled={ catId === "0" }
                            onClick={()=> !exists ? getPlaceData('ins') : null }>
                        ADD</button>
                    :
                    <></>
                }

                {/*{ dirId }&nbsp;&mdash;&nbsp;{ catId }*/}
            </td>
            {/*<td>*/}
            {/*    <button className={'btn btn-sm btn-secondary'} onClick={()=> !hasPlaceData ? getPlaceData('upd') : null }>*/}
            {/*        <i className={ (exists ? "fas" : "far") + " fa-star"}></i>*/}
            {/*    </button>*/}
            {/*</td>*/}
        </tr>
    )
}

const mapStateToProps = ( state:any ) => ({
    dirlist: state.directories.dirs,
});

export default connect(mapStateToProps, { })(PlaceView);

