import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainWrapper from './layout/MainWrapper';
import axios from 'axios';
import { apiRoot, userCanEdit } from '../services/helpers';
import moment from 'moment';

const Events = ( props:any ) => {
    const { auth, channels } = props;

    const initEvents:any = {
        records: [],
        pager: {
            i: 0,
            size: 25
        }
    };
    const [eventsLoaded, setEventsLoaded] = useState( -1 );
    const [events, setEvents] = useState( initEvents );
    const [alert, setAlert] = useState({ display:false, type:'success', message:'Story deleted successfully.' } );

    const getEvents = async () => {
        const chIds = channels.map( (ch:any) => parseInt(ch.id) );
        const data = {
            minDate: moment().toISOString(),
            maxDate: moment().add(1,'y').toISOString(),
            chIds: chIds,
        }
        const res: any = await axios.post(apiRoot() + '/events/group', data );
        if (res.data.success) {
            setEventsLoaded(1);
            setEvents(res.data.data);
        } else {
            setEventsLoaded(0);
        }
    }

    const hideAlert = () => {
        setAlert( { ...alert, display:false });
    }

    const deleteEvent = async ( eventId:string ) => {
        const yn = window.confirm('Are you sure you want to delete this event?');
        if (yn) {
            const res = await axios.delete( apiRoot()+'/events/info/'+eventId);
            if (res.data.success) {
                await getEvents();
                setAlert( { display:true, type:'success', message:'Your event was deleted.' } );
            } else {
                setAlert( { display:true, type:'danger', message:'An error occurred when deleted your event.' } );
            }
        }
    }

    useEffect(() => {
        if (eventsLoaded === -1) {
            (async () => {  await getEvents();  })();
        }
    }, [eventsLoaded]);

    return (
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <h3 className={'subtitle'}>
                            <Link to={'/events/new'} className="btn btn-success btn-sm">Create Event</Link>
                            Events</h3>
                        { alert.display ?
                            <div className={'alert alert-'+alert.type}>
                                <div style={{float:'right'}} onClick={()=>hideAlert()}><i className={'fas fa-times'}></i> </div>
                                <b>{ alert.message }</b>
                            </div>
                            : <></>
                        }
                        {eventsLoaded === 1 ?
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Channel</th>
                                    <th>Event Title</th>
                                    <th># Dates</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {events.records.map((e: any) =>
                                    <tr key={'events-'+e.evId}>
                                        <td>{e.evId}</td>
                                        <td><img src={e.chAvatar} className={'news-avatar'} alt={e.chName}/></td>
                                        <td>{e.evTitle}</td>
                                        <td className={'no-wrap'}>{e.num_dates}</td>
                                        <td className={'no-wrap'}>
                                            { userCanEdit( auth, e.chId) ?
                                                <>
                                                <Link to={'/events/'+e.evId} className={"btn btn-info btn-sm"}><i className="fas fa-pencil"></i></Link>
                                                    &nbsp;&nbsp;
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteEvent(e.evId)}><i className="fas fa-trash"></i></button>
                                                </>
                                                :
                                                <>
                                                <button className="btn btn-info btn-sm btn-disabled" onClick={()=>{}}><i className="fas fa-pencil"></i></button>
                                                    &nbsp;&nbsp;
                                                <button className="btn btn-danger btn-sm btn-disabled" onClick={()=>{}}><i className="fas fa-trash"></i></button>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    channels: state.auth.channels,
    isAdmin: state.auth.admin === 100
});

export default connect(mapStateToProps, { })(Events);

