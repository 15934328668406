import { SET_LOCATIONS, CLEAR_LOCATIONS, USER_LOADED } from '../types';
import { apiRoot } from '../../services/helpers';
import axios from 'axios';

export const getLocations = () => async (dispatch:any) => {
    try {
        const res:any = await axios.get( apiRoot() + '/auth/locations');
        if (res.data.success) {
            dispatch({type: SET_LOCATIONS, payload: res.data.locations});
        }
    } catch(err) {
        console.log('error getting locations');
    }
}

