import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import { apiRoot, userCanEdit } from '../services/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import MainWrapper from './layout/MainWrapper';
import DatePicker from 'react-datepicker';
import { RichTextEditor } from '@mantine/rte';
import moment from 'moment';

const EventForm = ( props:any ) => {
    const { auth, channels, locations } = props;

    const params:any = useParams();
    const eventId:any = params.id === 'new' ? 'new' : parseInt(params.id);
    const showNewOk:any = params.msg === '1';
    const navigate = useNavigate();

    const newEventDate:any = {
        user_id: auth.user_id,
        event_id: eventId,
        dateStart: null,
        dateEnd: null,
        location_id: 0
    }

    const blankEvent = {
        "dateId": "",
        "dateDescription": "Event description",
        "dateAllDay": "0",
        "edViews": "0",
        "edShares":"0",
        "evId": "New",
        "evTitle": "",
        "evDesc": "Event description",
        "evURL": "",
        "locId": "0",
        "locName": "",
        "locAdd1": "",
        "locAdd2": "",
        "locCity": "",
        "locState": "",
        "locZip": "",
        "chId": "0",
        "chName": "",
        "chSlug": "",
        "chAvatar": "",
        "chColorLight": "",
        "chColorDark": "",
        "uLname": "",
        "uFname": ""
    }

    const initEvent:any = {};
    const initArr:any = [];
    const initFile:File = new File([""], "filename");
    const [eventLoaded, setEventLoaded] = useState( -1 );
    const [event, setEvent] = useState( initEvent );
    const [rteContent, setRteContent] = useState( eventId === 'new' ? 'Enter event description here.' : 'not loaded' );
    const [eventDate, setEventDate] = useState( new Date() );
    const [dateList, setDateList] = useState( initArr );
    const [hideNewOk, sethideNewOk] = useState( false );
    const [newDate, setNewDate] = useState( newEventDate );
    const [alert, setAlert] = useState({ display:false, type:'success', message:'Story deleted successfully.' } );

    const getEvent = async () => {
        if (eventId === 'new') {
            setEventLoaded(1);
            setEvent(blankEvent);
            setDateList([]);
            setRteContent( blankEvent.evDesc );
            const setDate = new Date();
            setEventDate( setDate );
        } else {
            const res: any = await axios.get(apiRoot() + '/events/edit/'+eventId );
            if (res.data.success) {
                setEventLoaded(1);
                setEvent(res.data.data[0]);
                setDateList(res.data.data);
                setRteContent( res.data.data[0].evDesc );
                const setDate = new Date( res.data.data.datePublished );
                setEventDate( setDate );
            } else {
                setEventLoaded(0);
            }
        }
    }


    const setEventField = ( field:string, value:any ) => {
        const tmpEvent:any = { ...event };
        tmpEvent[field] = value;
        setEvent( tmpEvent );
    }

    const changeEventDate = (dateField: string, dateId: string, datetime: string) => {
        if (dateId === 'new') {
            const tmpNewDate = { ...newDate };
            if (dateField === 'date' || dateField === 'start') {
                tmpNewDate.dateStart = new Date(datetime).toISOString();
            } else {
                tmpNewDate.dateEnd = new Date(datetime).toISOString();
            }
            setNewDate( tmpNewDate );
        } else {
            const tmpDateList = dateList.map((dl: any) => {
                dl.edited = dl.hasOwnProperty('edited') ? dl.edited : false;
                if (dl.dateId.toString() === dateId) {
                    if (dateField === 'date' || dateField === 'start') {
                        dl.dateStart = new Date(datetime).toISOString();
                    } else {
                        dl.dateEnd = new Date(datetime).toISOString();
                    }
                    dl.edited = true;
                }
                return dl;
            });
            setDateList(tmpDateList);
        }
    }

    const changeEventLocation = ( dateId:string, locationId:string ) => {
        if (dateId === 'new') {
            const tmpNewDate = { ...newDate };
            tmpNewDate.location_id = locationId;
            setNewDate( tmpNewDate );
        } else {
            const tmpDateList = dateList.map((dl: any) => {
                if (dl.dateId.toString() === dateId) {
                    dl.locId = locationId;
                }
                return dl;
            });
            setDateList(tmpDateList);
        }
    }

    const deleteDate = async ( dateId:string ) => {
        const yn = window.confirm('Are you sure you want to delete this event date?');
        if (yn) {
            if (eventId === 'new') {
                const tmpDateList = dateList.filter( (dl:any) => dl.dateId !== dateId);
                setDateList( tmpDateList );
            } else {
                const res = await axios.delete( apiRoot()+'/events/date/'+dateId);
                if (res.data.success) {
                    setDateList([]);
                    await getEvent();
                    setAlert( { display:true, type:'success', message:'Your event date was deleted.' } );
                } else {
                    setAlert( { display:true, type:'danger', message:'An error occurred when deleted your event date.' } );
                }
            }
        }
    }

    const saveEventDate = async ( dateId:string ) => {
        if (eventId === 'new') {
            const data = {
                dateId: dateList.length,
                evId: eventId,
                userId: auth.user_id,
                evDescription: rteContent,
                dateStart: newDate.dateStart,
                dateEnd: newDate.dateStart.substring(0,10) + newDate.dateEnd.substring(10),
                locId: newDate.location_id
            }
            console.log('newdata', data);
            const tmpDateList = [ ...dateList ];
            tmpDateList.push(data)
            setDateList( tmpDateList );
            //setNewDate( newEventDate );
        }
        else if ( dateId === 'new') {
            const data = {
                userId: auth.user_id,
                evId: eventId,
                evDescription: rteContent,
                dateStart: newDate.dateStart,
                dateEnd: newDate.dateStart.substring(0,10) + newDate.dateEnd.substring(10),
                locId: newDate.location_id,
            }
            const res = await axios.post( apiRoot()+'/events/date', data);
            if (res.data.success) {
                setDateList([]);  // TODO: TEST THIS
                await getEvent();
                setAlert( { display:true, type:'success', message:'Your event date was added successfully.' } );
            } else {
                setAlert( { display:true, type:'danger', message:'An error occurred when adding your event date.' } );
            }
        }
        else {
            const d = dateList.filter( (d:any) => d.dateId === dateId ).pop();
            if (d.hasOwnProperty('dateId')) {
                const data = {
                    edId: d.dateId,
                    userId: auth.user_id,
                    evId: eventId,
                    evDescription: rteContent,
                    dateStart: d.dateStart,
                    dateEnd: d.dateStart.substring(0,10) + d.dateEnd.substring(10),
                    locId: d.locId,
                }
                const res = await axios.put( apiRoot()+'/events/date', data);
                if (res.data.success) {
                    setDateList([]);  // TODO: TEST THIS
                    await getEvent();
                    setAlert( { display:true, type:'success', message:'Your event date was updated successfully.' } );
                } else {
                    setAlert( { display:true, type:'danger', message:'An error occurred when updating your event date.' } );
                }
            }
        }
    }

    const hideAlert = () => {
        setAlert( { ...alert, display:false });
    }

    const saveEventDetails = async () => {
        const data = {
            userId: auth.user_id,
            evId: eventId,
            evTitle: event.evTitle,
            evDescription: rteContent,
            evURL: event.evURL,
            chId: event.chId,
        }
        console.log(data);
        const res = await axios.put( apiRoot()+'/events/info', data);
        if (res.data.success) {
            setDateList([]);
            setNewDate( newEventDate );
            await getEvent();
            setAlert( { display:true, type:'success', message:'Your event details were updated successfully.' } );
        } else {
            setAlert( { display:true, type:'danger', message:'An error occurred when updating your event details.' } );
        }
    }

    const submitNewEvent = async () => {
        if (!dateList.length) {
            setAlert({ display:true, type:'danger', message:'New events must have at least one event date.' });
        } else {
            const evData = {
                'evTitle': event.evTitle,
                'evDescription': rteContent,
                'evUrl': event.evURL,
                'chId': event.chId,
                'userId': auth.user_id
            }
            const res = await axios.post( apiRoot()+'/events/info', evData);
            if (res.data.success && res.data.eventId) {
                for (const dl of dateList) {
                    const data = {
                        userId: auth.user_id,
                        evId: res.data.eventId,
                        evDescription: rteContent,
                        dateStart: dl.dateStart,
                        dateEnd: dl.dateStart.substring(0,10) + dl.dateEnd.substring(10),
                        locId: dl.locId,
                    }
                    await axios.post( apiRoot()+'/events/date', data);
                }
                window.location.href = '/events/'+res.data.eventId+"/1";
                // navigate('/events/'+res.data.eventId+"/1", { replace: true });
            }
        }
    }

    useEffect(() => {
        (async () => {
            if (eventLoaded === -1) {
                await getEvent();
            }
        })();
    }, [])

    return ( eventLoaded === 1 && (userCanEdit(auth, event.chId) || eventId === 'new') ?
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <h3 className={'subtitle'}>
                            { eventId === 'new' ? 'Create Event' : 'Edit Event'}
                        </h3>
                        <hr/>
                        { alert.display ?
                            <div className={'alert alert-'+alert.type}>
                                <div style={{float:'right'}} onClick={()=>hideAlert()}><i className={'fas fa-times'}></i> </div>
                                <b>{ alert.message }</b>
                            </div>
                            : <></>
                        }
                        { showNewOk && !hideNewOk ?
                            <div className={'alert alert-success'}>
                                <div style={{float:'right'}} onClick={()=>sethideNewOk(true)}><i className={'fas fa-times'}></i> </div>
                                <b>Your story has been created successfully! You can continue to edit it below.</b>
                            </div>
                            : <></>
                        }
                        <br/>
                        {(eventLoaded === 1 && eventId !== 'new') || eventId === 'new' ?
                            <div>
                                <div className="row">
                                    <div className={"col-6"}>
                                        <div className="form-group">
                                            <label style={{display:'block'}}>Event Id</label>
                                            <input type="text" className="form-control" value={event.evId} disabled={true} />
                                        </div>
                                        <div className="form-group">
                                            <label style={{display:'block'}}>Event Title</label>
                                            <input type="text" className="form-control" value={event.evTitle}
                                                   onChange={(e:any)=>setEventField('evTitle', e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label style={{display:'block'}}>Event Url &nbsp; (for more info)</label>
                                            <input type="text" className="form-control" value={event.evURL}
                                                   placeholder={'https://'}
                                                   onChange={(e:any)=>setEventField('evURL', e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label>Channel</label>
                                            <select className="form-control" value={event.chId}
                                                    onChange={(e:any)=>setEventField('chId', e.target.value)}>
                                                <option value={"0"}>-- Select Channel --</option>
                                                { channels.map( (c:any) =>
                                                  <option value={c.id} key={'chan-'+c.id}>{c.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={"col-6"}>
                                        <label>Event Description</label>
                                        {rteContent !== 'not loaded' &&
                                          <RichTextEditor value={rteContent} onChange={setRteContent}
                                                          controls={[
                                                              ['bold', 'italic', 'underline', 'strike'],
                                                              ['h1', 'h2', 'h3'],
                                                              ['unorderedList', 'orderedList'],
                                                              ['link', 'blockquote'],
                                                              ['alignLeft', 'alignCenter'], ['clean']
                                                          ]}
                                          />
                                        }
                                    </div>
                                </div>

                                {eventId !== 'new' ?
                                    <>
                                        <br/><br/>
                                        <button className="btn btn-success btn-md"
                                                onClick={() => saveEventDetails()}> &nbsp; Save Event
                                            Details &nbsp; </button>
                                        <hr/>
                                    </> : <></>
                                }

                                <div className="row">
                                    <div className={"col-12"}>
                                        <br/><br/>
                                        <h4 className={'subtitle'}>Upcoming Dates</h4>
                                        <table className='table'>
                                            <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Event Date</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Location</th>
                                                <th>Contributor</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { dateList.length && dateList.map((dl:any) =>
                                                <tr style={{backgroundColor:(dl.edited ? '#fdf4d0' : 'none')}}>
                                                    <td>{ dl.dateId }</td>
                                                    <td>
                                                        <DatePicker selected={ new Date(dl.dateStart) }
                                                                    className="form-control"
                                                                    onChange={(date: any) => changeEventDate( 'date', dl.dateId, date )}
                                                                    dateFormat="MM / dd / yyyy"
                                                        />
                                                    </td>
                                                    <td>
                                                        <DatePicker selected={ new Date(dl.dateStart) }
                                                                    className="form-control" showTimeSelect showTimeSelectOnly timeIntervals={30}
                                                                    onChange={(date: any) => changeEventDate( 'start', dl.dateId, date )}
                                                                    dateFormat="h:mm aa"
                                                        />
                                                    </td>
                                                    <td>
                                                        <DatePicker selected={ new Date(dl.dateEnd) }
                                                                    className="form-control" showTimeSelect showTimeSelectOnly timeIntervals={30}
                                                                    onChange={(date: any) => changeEventDate( 'end', dl.dateId, date )}
                                                                    dateFormat="h:mm aa"
                                                        />
                                                    </td>
                                                    <td>
                                                        <select className="form-control" value={dl.locId} onChange={( e:any )=>changeEventLocation(dl.dateId, e.target.value)}>
                                                            <option value={0}>-- SELECT LOCATION ---</option>
                                                            { locations.map(  (loc:any) =>
                                                                <option value={loc.id}>{loc.name}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td>{ dl.uFname }&nbsp;{ dl.uLname }</td>
                                                    <td style={{whiteSpace:'nowrap'}}>
                                                        <button className={'btn btn-sm btn-success'+(dl.edited ? '' : ' btn-disabled')} disabled={!dl.edited} onClick={()=>saveEventDate(dl.dateId)}><i className="fas fa-check"></i></button> &nbsp;
                                                        <button className={'btn btn-sm btn-danger'} onClick={()=>deleteDate(dl.dateId)}><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr style={{backgroundColor:'#dfefe1'}}>
                                                <td>New</td>
                                                <td>
                                                    <DatePicker selected={ newDate.dateStart === null ? null : new Date(newDate.dateStart) }
                                                                className="form-control"
                                                                onChange={(date: any) => changeEventDate( 'date', 'new', date )}
                                                                dateFormat="MM / dd / yyyy"
                                                    />
                                                </td>
                                                <td>
                                                    <DatePicker selected={ newDate.dateStart === null ? null : new Date(newDate.dateStart) }
                                                                className="form-control" showTimeSelect showTimeSelectOnly timeIntervals={30}
                                                                onChange={(date: any) => changeEventDate( 'start', 'new', date )}
                                                                dateFormat="h:mm aa"
                                                    />
                                                </td>
                                                <td>
                                                    <DatePicker selected={ newDate.dateEnd === null ? null : new Date(newDate.dateEnd) }
                                                                className="form-control" showTimeSelect showTimeSelectOnly timeIntervals={30}
                                                                onChange={(date: any) => changeEventDate( 'end', 'new', date )}
                                                                dateFormat="h:mm aa"
                                                    />
                                                </td>
                                                <td>
                                                    <select className="form-control" value={newDate.location_id} onChange={( e:any )=>changeEventLocation('new', e.target.value)}>
                                                        <option value={0}>-- SELECT LOCATION ---</option>
                                                        { locations.map(  (loc:any) =>
                                                            <option value={loc.id}>{loc.name}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td>You</td>
                                                <th>{ eventId === 'new' ?
                                                    <button className={'btn btn-sm btn-success'} onClick={()=>saveEventDate('new')}><i className="fas fa-plus"></i></button>
                                                    :
                                                    <button className={'btn btn-sm btn-success'} onClick={()=>saveEventDate('new')}><i className="fas fa-check"></i></button>
                                                }
                                                </th>
                                            </tr>
                                            </tbody>
                                        </table>

                                        {eventId === 'new' ?
                                            <>
                                                <hr/>
                                                <button className="btn btn-success btn-md"
                                                        onClick={() => submitNewEvent()}> &nbsp; Submit New Event &nbsp; </button>
                                            </> : <></>
                                        }
                                    </div>
                                    <div className={"col-6"}>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </MainWrapper>
        : eventLoaded === 1 ?
        <div className="alert alert-danger">
            <b>Sorry but you don't have access to edit this event.</b>
        </div>
        : <MainWrapper><p>Loading...</p></MainWrapper>
    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    channels: state.auth.channels,
    locations: state.locations.locations
});

export default connect(mapStateToProps, { })(EventForm);

