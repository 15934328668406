export const blankCommDirEntry = {
    "dName": "Community Directory",
    "dcId": null,
    "dcName": "Education",
    "dcSlug": "education",
    "dcIcon": "graduation",
    "dcRank": "10",
    "deId": null,
    "dePlaceId": "",
    "deName": "",
    "deDescription": "",
    "dePhone": "",
    "deWebsite": "",
    "deActive": "1",
    "deData": {},
    "dePlaceData": {
        "formatted_address": "13041 Frankstown Rd, Penn Hills, PA 15235, USA",
        "formatted_phone_number": "(412) 793-4222",
        "geometry": {
            "location": {
                "lat": 40.483323,
                "lng": -79.81994999999999
            }
        },
        "place_id": "ChIJI6ER5mXrNIgRUdpv_ls91uE",
        "url": "",
    },
    "dpCount": "0"
};

export const blankPlace = {
    "address_components": [],
    "adr_address": "",
    "business_status": "OPERATIONAL",
    "current_opening_hours": {
        "open_now": true,
        "periods": [],
        "weekday_text": []
    },
    "delivery": false,
    "dine_in": false,
    "editorial_summary": {
        "language": "en",
        "overview": "Fast-food outlet serving up namesake root beer, plus burgers, chicken & fries."
    },
    "formatted_address": "300 Rodi Rd, Penn Hills, PA 15235, USA",
    "formatted_phone_number": "(412) 731-5557",
    "geometry": {
        "location": {
            "lat": 40.460874,
            "lng": -79.82446
        },
        "viewport": {
            "northeast": {
                "lat": 40.4622893802915,
                "lng": -79.8231121197085
            },
            "southwest": {
                "lat": 40.4595914197085,
                "lng": -79.8258100802915
            }
        }
    },
    "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png",
    "icon_background_color": "#FF9E67",
    "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/restaurant_pinlet",
    "international_phone_number": "+1 412-731-5557",
    "name": "A&W Restaurant",
    "opening_hours": {
        "open_now": true,
        "periods": [
            {
                "close": {
                    "day": 0,
                    "time": "2000"
                },
                "open": {
                    "day": 0,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 1,
                    "time": "2000"
                },
                "open": {
                    "day": 1,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 2,
                    "time": "2000"
                },
                "open": {
                    "day": 2,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 3,
                    "time": "2000"
                },
                "open": {
                    "day": 3,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 4,
                    "time": "2000"
                },
                "open": {
                    "day": 4,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 5,
                    "time": "2100"
                },
                "open": {
                    "day": 5,
                    "time": "1100"
                }
            },
            {
                "close": {
                    "day": 6,
                    "time": "2100"
                },
                "open": {
                    "day": 6,
                    "time": "1100"
                }
            }
        ],
        "weekday_text": [
            "Monday: 11:00 AM – 8:00 PM",
            "Tuesday: 11:00 AM – 8:00 PM",
            "Wednesday: 11:00 AM – 8:00 PM",
            "Thursday: 11:00 AM – 8:00 PM",
            "Friday: 11:00 AM – 9:00 PM",
            "Saturday: 11:00 AM – 9:00 PM",
            "Sunday: 11:00 AM – 8:00 PM"
        ]
    },
    "photos": [
        {
            "height": 3456,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFhSps90fSjj-F-TZFlIbE_RUo6mWmMsBzNzqh-c3t_1WxA6iv4OF9cH4ikQgU2a1mcWM2YekZYDWUdS_dmZonzx_-lzTvW2IVFsbR4WhIPbpXa5lZfLGBPDaevOwR6XHBYKlFyp-MKycdsO0Hmuj4W5O3sIg-mDNr5rCbZyEtvnKHbU",
            "width": 4608
        },
        {
            "height": 3456,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFhnq_eU_z2ckMkU-oyGQiHsv27al_4QE9tffPZth8rYFkwNpfH75WNH5CsyGd72DoBNhddX0EICmJIZv1JulZUvVM4fh1oJlK1_qyNQ0yVytWONp9EM8p0yj_vPRjMHdqe0COMtjcB0iVK1yHiJHPONJHOZhib_T6mkV0ntsiyB-c58",
            "width": 4608
        },
        {
            "height": 3456,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFgHH7NWU2X8bQvfRZ2gW3Llm3yvNKfTUj3ohdJLC75lpZSOw5WZXJ_dSDwWvIkMd0L5X3plhjoi_HSSDilIsRyuwPLUobLenSBucqQ0cgW-E-CXrIw8aywbHWZWW1Fr3qA9ub_VIpfrF4kO5w-VV4SgqMaD2SH9u9SVyfImk8ejlJJX",
            "width": 4608
        },
        {
            "height": 4608,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFjWruINOi3iVBeiIqMJig8WrZ3nL6Kq7peGWQUzK_g0XoXXD9_4j_YFdXOvHrR9kzrTw1rRHEAGQq9yqaeK48Jr3BYohALMOZjXCvnmdOgZ1ZrPbt0i7dtsYh327U2Zn2d3B2ZMaXC-59NjbJSNWIP_mTe1KYl2jUgys_FoL3WZfCHt",
            "width": 3456
        },
        {
            "height": 3456,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFgPLgFQkI2OvKvF07lYhR2Xh8p0L1thIZOWtQ3cRF6EuPZMFjy73kYzn8rgoDCGQSb6As5Z7Frcek2qqNarXXR2yOxU-Y0z2JS9EFbg0FYzFTp6j2VDDVsDEcShFhWDZkDM9Irzbpq49LOGwpWXygJhx1NLU7RRcGKQVBKpEKw81lpr",
            "width": 4608
        },
        {
            "height": 3456,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111897328728228005296\">Allan “Keep Life Simple” Sensenich</a>"
            ],
            "photo_reference": "AWU5eFhxUrfEZW-AyVio5QWb9qlSvZypm4HUq4mOW6sm0XMnxJKbrXyb1FK3NfPqLkMzMH3nCurLy5892CR1lkJgVzoBwQAkiudpnnSwX0UJrS2RON6pDfE_6e-A_-rWVgv7c0PUHTHXZud8VJSWKWHTOeZTz5plKhu2Ast9TedPiIVl3_Gd",
            "width": 4608
        }
    ],
    "place_id": "ChIJMT0FcYbrNIgRVxsS6P5LJyY",
    "plus_code": {
        "compound_code": "F56G+86 Penn Hills, PA, USA",
        "global_code": "87G2F56G+86"
    },
    "price_level": 1,
    "rating": 3.5,
    "reference": "ChIJMT0FcYbrNIgRVxsS6P5LJyY",
    "reviews": [
        {
            "author_name": "Michael Guajardo",
            "author_url": "https://www.google.com/maps/contrib/106819193640014479438/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjVWhPy1OdaAHit7gL6aHnbRpBZgXyMkIuCjXfID2dTDZij1=s128-c0x00000000-cc-rp-mo-ba4",
            "rating": 5,
            "relative_time_description": "5 months ago",
            "text": "They are so efficient and attentive here.  Never a problem with my order and the guy at the window is amazing and great with his customer service.",
            "time": 1690287669,
            "translated": false
        },
        {
            "author_name": "Robin M.",
            "author_url": "https://www.google.com/maps/contrib/102043697520122893773/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjWYelgIQou5MS7LgGPsiOpNVNWuVgSOxV-UZ7THe_MNtM4=s128-c0x00000000-cc-rp-mo-ba4",
            "rating": 3,
            "relative_time_description": "4 weeks ago",
            "text": "Edit: Recently stopped in and had a pretty good experience. Food was hot and fresh. Staff was friendly.\nOriginal Review: I do like the food here. However, sometimes it's better than other times. This also goes along with the staff and cleanliness of the dining room. Price is midrange. It's not exactly cheap to feed your family here. Rootbeer is good! We do enjoy stopping here occasionally.",
            "time": 1701262720,
            "translated": false
        },
        {
            "author_name": "Stanley Burleigh",
            "author_url": "https://www.google.com/maps/contrib/117697077770153158719/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLrKt_BFwloW2SWHVcMjmrWZMwf-UEofgxns6B4FOh9=s128-c0x00000000-cc-rp-mo-ba6",
            "rating": 4,
            "relative_time_description": "5 months ago",
            "text": "Kinda 50's looks very nicely done\nReally Loved the look.  Fish & shrimp were just okay.",
            "time": 1690524406,
            "translated": false
        },
        {
            "author_name": "Dawn Jones",
            "author_url": "https://www.google.com/maps/contrib/112003919495804961063/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLUd8BdmMQfrQ6UP2Qsj_GsuOwx7OR56XM1Ugsu-OfG=s128-c0x00000000-cc-rp-mo",
            "rating": 2,
            "relative_time_description": "7 months ago",
            "text": "Did not get the burger I ordered. There's never customers in drive thru but not making orders correctly. I ordered a bbq bacon crunch burger. I received no bbq or crunch 🤦🏿‍♀️",
            "time": 1683667667,
            "translated": false
        },
        {
            "author_name": "Mark D",
            "author_url": "https://www.google.com/maps/contrib/100763869326591930029/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjXSlpxq3ruFTicZCmAUPCtwkUAY0Eeo8uvvQ-Kf7XILcMSo=s128-c0x00000000-cc-rp-mo-ba6",
            "rating": 1,
            "relative_time_description": "2 years ago",
            "text": "Always crazy slow.  I just sat in the drive thru for 37 minutes with only 3 cars in front of me.  I'm assuming there is a lack of employees, but ownership could obviously make more money if they could speed this process up.  I typically pass the restaurant as I can't wait in the line as long as it takes.  Food is good, just not worth the hassle.",
            "time": 1611518127,
            "translated": false
        }
    ],
    "serves_beer": false,
    "serves_dinner": true,
    "serves_lunch": true,
    "serves_wine": false,
    "takeout": true,
    "types": [
        "restaurant",
        "point_of_interest",
        "food",
        "establishment"
    ],
    "url": "https://maps.google.com/?cid=2749249655720254295",
    "user_ratings_total": 231,
    "utc_offset": -300,
    "vicinity": "300 Rodi Road, Penn Hills",
    "website": "https://awrestaurants.com/locations/pennsylvania/penn-hills/300-rodi-rd/",
    "wheelchair_accessible_entrance": true
}
