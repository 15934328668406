import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PlaceView from './PlaceView';
import MainWrapper from './layout/MainWrapper';
import { apiRoot } from '../services/helpers';

const DirectoriesNew = (props: any) => {
    const {} = props;

    const params: any = useParams();
    const dirId: any = params.dId;

    const initArr: any = [];
    const [loading, setLoading] = useState({ active: false, label: '' });
    const [nextToken, setNextToken] = useState('');
    const [places, setPlaces] = useState(initArr);
    const [coords, setCoords] = useState("40.50359063021925, -79.84225153474401" );

    const catFood = 'restaurant|bar|cafe|meal_delivery|meal_takeaway';
    const catRetail = 'store';
    const catHealth = 'doctor|dentist|hospital';

    function splitCoordinates( c:string ) {
        // Split the input string by the comma
        const coordinates = c.split(',');

        // Convert the split strings into numbers and return them as an array
        const latitude = parseFloat(coordinates[0]);
        const longitude = parseFloat(coordinates[1]);

        return [latitude, longitude];
    }

    const getPlaceList = async (nextpage = false) => {
        setLoading({ active: true, label: 'Loading Places' });
        try {
            const c = splitCoordinates( coords );
            const data:any = {
                lat: c[0],
                lng: c[1],
            };
            if (nextToken.length) {
                data.nextToken = nextToken;
            }
            let placeList = await axios.post( apiRoot()+'/directory/places', data );
            if (placeList.data.success) {
                console.log('res', placeList.data.places );
                setPlaces([...places, ...placeList.data.places.results]);
                setNextToken(placeList.data.places.next_page_token ? placeList.data.places.next_page_token : '');
                // console.log(placeList.data.results);
            } else {
            }
        } catch (err) {
            console.log('ERROR:', err);
        }
    }


    return (
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        {!places.length || nextToken.length ?
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center', marginBottom:'20px'}}>
                                <div><b>COORDS: &nbsp; </b></div>
                                <div style={{flex:1}}> <input type="text" value={coords} className={"form-control"}
                                                 onChange={(e: any) => setCoords(e.target.value)}/></div>
                                <div>  &nbsp;
                                    <button className={'btn btn-primary'} onClick={() => getPlaceList()}>Get Places</button>
                                </div>

                            </div>
                            :
                            <></>
                        }
                        <table className={'table table-striped'}>
                            <thead>

                            </thead>
                            <tbody>
                            {places.map((p: any, i: number) =>
                                <PlaceView p={p} key={p.place_id + i}/>
                            )}
                            </tbody>
                        </table>

                        { nextToken.length ?
                            <div>  &nbsp;
                                <button className={'btn btn-primary'} onClick={() => getPlaceList()}>Get More Places</button>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(DirectoriesNew);

