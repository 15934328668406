import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { removeAlert } from '../redux/actions/alert';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../redux/actions/profile';
import { getChannels, loadUser, logout } from '../redux/actions/auth';
import { getDirectories } from '../redux/actions/directories';

import Login from '../screens/auth/Login';
import Dashboard from '../screens/Dashboard';
import { getLocations } from '../redux/actions/locations';
import PwReset from '../screens/PwReset';
import PwResetForm from '../screens/PwResetForm';
import Users from '../screens/Users';
import News from '../screens/News';
import NewsForm from '../screens/NewsForm';
import Events from '../screens/Events';
import EventsForm from '../screens/EventsForm';
import locations from '../redux/reducers/locations';
import Directories from '../screens/Directories';
import DirectoriesNew from '../screens/DirectoriesNew';

const Router = ( props: any ) => {

    const { removeAlert, auth, profile, locations, loadUser, getLocations, getChannels, logout, directories, getDirectories } = props;

    const [authChecked, setAuthChecked] = useState( false );

    const isAuthenticated = auth.isAuthenticated;

    // LOAD USER
    useEffect( () => {
        (async () => {
            if (!auth.isAuthenticated) await loadUser();
            setAuthChecked( true );
        })();
    }, [auth]);

    // GET LOCATIONS
    useEffect(() => {
        (async () => { if (isAuthenticated && !locations.locations.length) { await getLocations(); } })();
    }, [locations, auth]);

    // GET CHANNELS
    useEffect(() => {
        (async () => { if (isAuthenticated && !auth.channels.length) { await getChannels(); } })();
    }, [auth]);

    // GET DIRECTORIES
    useEffect(() => {
        (async () => { if (isAuthenticated && !directories.dirs.length) { await getDirectories(); } })();
    }, [auth]);

    // CHECK TOKEN EXPIRATION, LOGOUT IF EXPIRED
    useEffect(() => {
        if (auth && auth.token && auth.token_expires && auth.token_expires < Math.round(+new Date()/1000)) {
            logout();
        }
    }, [auth]);

    return (authChecked ?

            isAuthenticated ?
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/users" element={<Users />} />
                    <Route path="/dashboard" element={<Dashboard />}/>
                    <Route path="/events" element={<Events />}/>
                    <Route path="/events/:id" element={<EventsForm />}/>
                    <Route path="/events/:id/:msg" element={<EventsForm />}/>
                    <Route path="/news" element={<News />}/>
                    <Route path="/news/:id" element={<NewsForm />}/>
                    <Route path="/news/:id/:msg" element={<NewsForm />}/>
                    <Route path="/directories/new" element={<DirectoriesNew />}/>
                    <Route path="/directories/:dId" element={<Directories />}/>
                    <Route path="/" element={<Login/>}/>
                </Routes>
                :
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/pwreset" element={<PwReset/>}/>
                    <Route path="/pwreset-form/:hash" element={<PwResetForm/>}/>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/:any/:any/:any" element={<Login/>}/>
                    <Route path="/:any/:any" element={<Login/>}/>
                    <Route path="/:any" element={<Login/>}/>
                </Routes>

            : <></>
    );
}


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile,
    locations: state.locations,
    directories: state.directories,
});

export default connect(mapStateToProps, { getCurrentProfile, removeAlert, loadUser, getLocations, getChannels, getDirectories, logout })(Router);
