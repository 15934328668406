import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

import { setAlert } from '../../redux/actions/alert';
import Alert from '../layout/Alert';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, logout } from '../../redux/actions/auth';


const Login = (props: any) => {

    const { auth, setAlert, login, logout } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState( false );
    const [status, setStatus] = useState( '' );

    const isAuthenticated = !!(auth && auth.user_id && auth.token);
    const isAdmin = isAuthenticated && auth.admin === "1";

    const navigate = useNavigate();
    let location = useLocation();

    useEffect( () => {
        console.log('isAuthenticated', isAuthenticated);
        if (isAuthenticated) {
            navigate('/users');
        } else if (location.pathname !== '/login') {
            console.log('loc',location);
            navigate('/login');
        }
    }, [auth]);

    return (
        <div className='pf-login'>

            {/*<img src={logo}/>*/}
            {/*<div className="logo-title">Penn Hills Passport</div>*/}

            <div className='pf-login-panel'>

                <h1>Penn Hills Passport - Admin Login</h1>

                <Alert alertKey="signinError"/>

                <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="far fa-envelope"></i></div>
                        </div>
                        <input type="text" className="form-control" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                </div>

                <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="far fa-key"></i></div>
                        </div>
                        <input type="password" className="form-control" value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                </div>

                <button className="btn btn-primary btn-block btn-lg btn-flex" onClick={() => login(email, password)}>
                    <div>Sign In</div>
                    <i className="fas fa-arrow-right"></i>
                </button>

            </div>

            <div className="more-actions">
                <Link to={'/pwreset'} className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-lock-alt fa-fw"></i> &nbsp;Reset Your Password</div>
                    <i className="fas fa-arrow-right"></i>
                </Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, login, logout })(Login);


