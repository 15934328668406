import moment from 'moment';

export const apiRoot = () => {
    const theURL:string = document.location.href;
    return 'https://api.pennhillspassport.com/v1';

    if ( theURL.includes('localhost') ) {
        //return 'https://api.pennhillscdc.org/v1';
        return 'http://localhost:8888/index.php/v1';
    } else {
        return 'https://api.pennhillspassport.com/v1';
    }
}


export const userCanEdit = ( user:any, channelId:string ) => {
    let canEdit = false;
    if (parseInt(user.admin) === 100) {
        return true;
    }
    else {
        user.channels.map( (uChannel:any) => {
            if (parseInt(uChannel.channel_id) === parseInt(channelId)) {
                canEdit = true;
            }
        })
    }
    return canEdit;
}


export const formatPhone = ( num:string ) => {
    let a = num.substring(0,3);
    let b = num.substring(3,6);
    let c = num.substring(6);

    return a+'-'+b+'-'+c;
}

export const yearApplied = () => {
    let year = parseInt(moment().format('YYYY'));
    let month = parseInt(moment().format('M'));

    let yearApplied = year;

    if (month > 10) {
        yearApplied++;
    }
    else if ( month === 10 ) {
        // find the 3rd thursday of the month
        // min possible is the 15th, max is the 21st
        let checkDay = 14;
        let dateFound = false;
        let d = '';

        do {
            checkDay++;
            d = '10/'+checkDay+'/'+year;
            dateFound = moment(d, 'MM/DD/YYYY', true).format('ddd') === 'Thu';
        } while (!dateFound);

        d = '10/'+(checkDay-4)+'/'+year;
        const today = parseInt(moment().format('YYYYMMDD'));
        const votingDay = parseInt(moment(d, 'MM/DD/YYYY', true).format('YYYYMMDD'));

        yearApplied = today > votingDay ? yearApplied+1 : yearApplied;
    }

    return yearApplied;
}


export const getVotingDate = ( year:number ) => {

    // find the 3rd thursday of the month
    // min possible is the 15th, max is the 21st
    let checkDay = 14;
    let dateFound = false;
    let d = '';

    do {
        checkDay++;
        d = '10/'+checkDay+'/'+year;
        dateFound = moment(d, 'MM/DD/YYYY', true).format('ddd') === 'Thu';
    } while (!dateFound);

    const votingDay = parseInt(moment( 'October '+checkDay+', '+year).format('YYYYMMDD'));
    return votingDay;
}

export const checkVotingDate = ( year:number, payDate:Date ) => {
    const voteDate = getVotingDate( year );
    const checkDate = parseInt(moment( payDate ).format('YYYYMMDD'));

    return voteDate > checkDate;
}

export const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            backgroundColor: "#FFFFFF",
            padding: "5px",
            color: "#32325d",
            fontFamily: '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            borderWidth: "1px",
            borderRadius: "4px",
            borderColor: "#ADB5BD",
            marginTop: "2px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export const ucwords = ( string:string ) => {
    return string[0].toUpperCase() + string.substring(1);
}


export const validateEmail = (email:string) => {
    return email.toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const usd = (x:any) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(x); /* $2,500.00 */
}

export const numComma = (x:number) => {
    try {
        let n = x.toString();
        n = x.toFixed(0);
        return n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch {
        return '0';
    }
}

export const pct = (x:any) => {
    x = x * 100;
    try {
        let n = x.toString();
        n = x.toFixed(0);
        return n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch {
        return '0';
    }
}

export const wait = (timeout:number) => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}
