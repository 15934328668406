import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import MainWrapper from './layout/MainWrapper';
import axios from 'axios';
import { apiRoot } from '../services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';

const News = ( props:any ) => {
    const { channels } = props;

    const initNews:any = {
        records: [],
        pager: {
            i: 0,
            size: 100,
            feed: -1
        },
    };
    const [newsLoaded, setNewsLoaded] = useState( -1 );
    const [news, setNews] = useState( initNews );
    const [alert, setAlert] = useState({ display:false, type:'success', message:'Story deleted successfully.' } );

    const getNews = async () => {
        const chIds = channels.map( (ch:any) => parseInt(ch.id) );
        const data = { ...news.pager, chIds };
        const res: any = await axios.post(apiRoot() + '/news/list', data );
        if (res.data.success) {
            setNewsLoaded(1);
            setNews(res.data.data);
        } else {
            setNewsLoaded(0);
        }
    }



    const deleteStory = async ( storyId:number ) => {
        const yn = window.confirm('Are you sure you want to delete this news story?');
        if (yn) {
            const res = await axios.delete(apiRoot() + '/news/story/'+storyId);
            if (res.data.success) {
                setAlert({ ...alert, display: true, type: 'success', message: 'Story deleted successfully.' });
                await getNews();
            } else {
                setAlert({ ...alert, display: true,
                    type: 'danger', message: res.data.errors[0]
                });
            }
        }
    }

    const hideAlert = () => {
        setAlert( { ...alert, display:false });
    }

    useEffect(() => {
        if (newsLoaded === -1) {
            (async () => {  await getNews();  })();
        }
    }, [newsLoaded, channels]);

    return (
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <h3 className={'subtitle'}>
                            <Link to={'/news/new'} className="btn btn-success btn-sm">Create Story</Link>
                            News</h3>
                        { alert.display ?
                            <div className={'alert alert-'+alert.type}>
                                <div style={{float:'right'}} onClick={()=>hideAlert()}><i className={'fas fa-times'}></i> </div>
                                <b>{ alert.message }</b>
                            </div>
                            : <></>
                        }
                        {newsLoaded === 1 ?
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Channel</th>
                                    <th>Published</th>
                                    <th>Headline</th>
                                    <th>Author</th>
                                    <th>Contributor</th>
                                    <th>Views</th>
                                    <th>Shares</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {news.records.map((n: any) =>
                                    <tr key={'news-'+n.id}>
                                        <td>{n.id}</td>
                                        <td><img src={n.avatar} className={'news-avatar'} alt={n.name}/></td>
                                        <td>{ moment(n.datePublished).format('MM/DD/YYYY')}</td>
                                        <td>{n.headline}</td>
                                        <td className={'no-wrap'}>{n.author}</td>
                                        <td className={'no-wrap'}>{n.fname} {n.lname}</td>
                                        <td className={'text-center'}>{n.views}</td>
                                        <td className={'text-center'}>{n.shares}</td>
                                        <td className={'no-wrap'}>
                                            <Link to={'/news/'+n.id} className="btn btn-info btn-sm"><i className="fas fa-pencil"></i></Link>
                                            &nbsp;&nbsp;
                                            <button className="btn btn-danger btn-sm" onClick={()=>deleteStory(n.id)}><i className="fas fa-trash"></i></button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

const mapStateToProps = ( state:any ) => ({
    channels: state.auth.channels
});

export default connect(mapStateToProps, { })(News);

