import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../redux/actions/auth';
import { createNewMemberPlan, getMembers } from '../redux/actions/members';
import MainWrapper from './layout/MainWrapper';

const Dashboard = (props: any) => {
    const { auth, members, logout } = props;

    return (
        <MainWrapper></MainWrapper>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    members: state.members
});

export default connect(mapStateToProps, { logout, getMembers, createNewMemberPlan })(Dashboard);

