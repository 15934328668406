import { SET_LOCATIONS, CLEAR_LOCATIONS } from '../types';

const initialState:any = {
    locationsLoaded: false,
    locations: [],
}

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case SET_LOCATIONS:
            return {
                ...state,
                locationsLoaded: true,
                locations: payload
            }

        case CLEAR_LOCATIONS:
            return initialState;

        default:
            return state;
    }
}
